import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import { DriverReportOptions } from '../../../axios';
import Loader from '../../../Components/Loader';
import useErrorHandler from '../../../Hooks/UseErrorHandler';
import { addNotification } from '../../../Slices/NotificationSlice';
import theme from '../../../theme';
import { DriverReportOption, ModeEnum, Organization, ReportingCompany } from '../../../Types';

export interface AddEditDriverRptOptionDialogProps<T> {
  mode: ModeEnum.Create | ModeEnum.Update;
  item?: T;
  selectedOrg: Organization;
  selectedRptCompany: ReportingCompany | null;
  onAction: () => void;
  onCreated: (result: DriverReportOption) => void;
  onUpdated: (result: DriverReportOption) => void;
}

interface FormFieldsProps {
  note: string;
  years: number | string;
  assignToAllUsers: boolean;
  makeDefaultDRO: boolean;
}

const AddEditDriverRptOptionDialog: FC<AddEditDriverRptOptionDialogProps<DriverReportOption>> = ({
  mode,
  item,
  selectedOrg,
  selectedRptCompany,
  onAction,
  onCreated,
  onUpdated,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleError = useErrorHandler();
  const [open, setOpen] = useState<boolean>(true);
  const [formFields, setFormFields] = useState<FormFieldsProps>({
    note: item && item.note ? item.note : '',
    years: item && item.yearsOfData ? item.yearsOfData : 25,
    assignToAllUsers: false,
    makeDefaultDRO: false,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const optionsYearsSelect = () => {
    const years: Array<number> = [];
    for (let i = 6; i <= 25; i++) {
      years.push(i);
    }
    return years.reverse();
  };

  const handleSave = async () => {
    setIsLoading(true);
    setOpen(false);
    const params = {
      note: formFields.note,
      yearsOfData: formFields.years === 'MAX' ? null : (formFields.years as number),
      organizationId: selectedOrg.id,
      reportingCompanyId: selectedRptCompany ? selectedRptCompany.id : undefined,
    };

    if (mode === ModeEnum.Create) {
      await DriverReportOptions.create({
        ...params,
        assignToAllUsers: formFields.assignToAllUsers,
        makeDefaultDRO: formFields.makeDefaultDRO,
      })
        .then((dro) => {
          dispatch(addNotification(200, 'success', t('successMessage.created', { resource: dro.yearsOfData })));
          onCreated(dro);
          setIsLoading(false);
        })
        .catch((error) => {
          handleError(error, { error409: t('errorMessage.error409Duplicate') });
          setIsLoading(false);
        });
    } else if (item) {
      await DriverReportOptions.update(item.id, params)
        .then((dro) => {
          dispatch(addNotification(200, 'success', t('successMessage.updated', { resource: dro.yearsOfData })));
          onUpdated(dro);
          setIsLoading(false);
        })
        .catch((error) => {
          handleError(error, { error409: t('errorMessage.error409Duplicate') });
          setIsLoading(false);
        });
    }

    onAction();
  };

  const handleClose = () => {
    setOpen(false);
    onAction();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={(_, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            handleClose();
          }
        }}
        fullWidth
        data-testid="AddDriverRptOptionDialogComponent"
      >
        <Typography variant="h5" sx={{ paddingLeft: theme.spacing(4), paddingTop: theme.spacing(4) }}>
          {mode === ModeEnum.Create
            ? `${t('userAdmin.companyProf.add')} ${t('userAdmin.companyProf.dialogTitleDro')}`
            : `${t('userAdmin.companyProf.edit')} ${t('userAdmin.companyProf.dialogTitleDro')}`}
        </Typography>

        <DialogContent sx={{ padding: theme.spacing(4) }}>
          <Grid container spacing={0.5}>
            <Grid item container xs={12} spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Select
                  fullWidth
                  size="small"
                  margin="none"
                  id="years-select"
                  value={formFields.years}
                  onChange={(e) => setFormFields({ ...formFields, years: e.target.value })}
                  data-testid="select"
                >
                  <MenuItem value={'MAX'}>{t('userAdmin.companyProf.maxYears')}</MenuItem>
                  {optionsYearsSelect().map((oneYear, index) => (
                    <MenuItem key={index} value={oneYear}>
                      {`${oneYear} ${t('userAdmin.companyProf.years')}`}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  size="small"
                  margin="none"
                  id="name"
                  value={formFields.note}
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  placeholder={t('userAdmin.companyProf.addNotePlaceholder')}
                  autoComplete="nope"
                  sx={{ paddingRight: '0' }}
                  onChange={(e) => setFormFields({ ...formFields, note: e.target.value })}
                  inputProps={{ 'data-testid': 'textfield', maxLength: 100 }}
                />
              </Grid>
              {mode === ModeEnum.Create && (
                <>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{ padding: '0px 9px' }}
                          disableRipple
                          checked={formFields.assignToAllUsers}
                          onChange={(e) => setFormFields({ ...formFields, assignToAllUsers: e.target.checked })}
                        />
                      }
                      label={t('userAdmin.companyProf.assignToAllUsers') as string}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{ padding: '0px 9px' }}
                          disableRipple
                          checked={formFields.makeDefaultDRO}
                          onChange={(e) => setFormFields({ ...formFields, makeDefaultDRO: e.target.checked })}
                        />
                      }
                      label={t('userAdmin.companyProf.makeDefaultDRO') as string}
                      disabled={!formFields.assignToAllUsers}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions
          sx={{ paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4), paddingBottom: theme.spacing(4) }}
        >
          <Grid container spacing={2} sx={{ justifyContent: 'end' }}>
            <Grid item xs={3}>
              <Button
                fullWidth
                data-testid="cancel"
                variant="contained"
                color="secondary"
                onClick={() => handleClose()}
              >
                {t('dialog.cancel')}
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button fullWidth data-testid="accept" variant="contained" color="primary" onClick={() => handleSave()}>
                {t('dialog.save')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <Loader open={isLoading} />
    </>
  );
};

export default AddEditDriverRptOptionDialog;

import { requests } from '../axios';
import { DriverReportOption, DriverReportOptionFactory } from '../Types';

export const createDRO = (params: {
  note: string;
  yearsOfData: number | null;
  organizationId: number;
  reportingCompanyId?: number;
  assignToAllUsers: boolean;
  makeDefaultDRO: boolean;
}): Promise<DriverReportOption> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<DriverReportOption>((resolve) => {
      const { note, yearsOfData, organizationId, reportingCompanyId } = params;
      resolve(DriverReportOptionFactory(2, note, organizationId, false, yearsOfData, reportingCompanyId));
    });
  } else {
    return requests.post('/DriverReportOptions/create', params);
  }
};

export const updateDRO = (
  id: number,
  params: {
    note: string;
    yearsOfData: number | null;
    organizationId: number;
    reportingCompanyId?: number;
  }
): Promise<DriverReportOption> => {
  return requests.put(`/DriverReportOptions/${id}`, params);
};

export const disableDRO = (id: number): Promise<DriverReportOption> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<DriverReportOption>((resolve) => {
      resolve(DriverReportOptionFactory(1, 'test note', 1, false, 25, 1));
    });
  } else {
    return requests.delete(`/DriverReportOptions/${id}`);
  }
};

export const getDriverReportOptionsByCompanyId = (
  id: number,
  params?: { reportingCompanyId: number }
): Promise<DriverReportOption[]> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<DriverReportOption[]>((resolve) => {
      resolve([
        DriverReportOptionFactory(1, 'test note', id, false, 25, params?.reportingCompanyId),
        DriverReportOptionFactory(2, 'test note 2', id, false, 10, params?.reportingCompanyId),
      ]);
    });
  } else {
    return requests.get(`/DriverReportOptions/GetByOrganizationId/${id}`, params ? params : {});
  }
};

export const listDRO = (userId?: number | null): Promise<DriverReportOption[]> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<DriverReportOption[]>((resolve) => {
      resolve([
        DriverReportOptionFactory(1, 'test note', 1, false, 25, 1),
        DriverReportOptionFactory(2, 'test note 2', 1, false, 10, 2),
        DriverReportOptionFactory(3, 'test note 3', 2, false, 25, 3),
        DriverReportOptionFactory(4, 'test note 4', 2, false, 10, 4),
        DriverReportOptionFactory(5, 'test note 5', 2, false, 25, 5),
        DriverReportOptionFactory(6, 'second test note 5', 2, false, 7, 5),
        DriverReportOptionFactory(7, 'test note 6', 3, false, 10, 6),
        DriverReportOptionFactory(8, 'test note 7', 3, false, 25, 7),
      ]);
    });
  } else {
    return requests.get(`/DriverReportOptions/list?userId=${userId ?? ''}`, {});
  }
};

export const listByUserAccessDRO = async (): Promise<DriverReportOption[]> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<DriverReportOption[]>((resolve) => {
      resolve([
        DriverReportOptionFactory(1, 'test note', 1, false, 25, 1),
        DriverReportOptionFactory(2, 'test note 2', 1, false, 10, 2),
        DriverReportOptionFactory(3, 'test note 3', 2, false, 25, 3),
        DriverReportOptionFactory(4, 'test note 4', 2, false, 10, 4),
        DriverReportOptionFactory(5, 'test note 5', 2, false, 25, 5),
        DriverReportOptionFactory(6, 'second test note 5', 2, false, 7, 5),
        DriverReportOptionFactory(7, 'test note 6', 3, false, 10, 6),
        DriverReportOptionFactory(8, 'test note 7', 3, false, 25, 7),
      ]);
    });
  } else {
    return await requests.get('/DriverReportOptions/get', {});
  }
};

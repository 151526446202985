interface DriverReportOption {
  id: number;
  organizationId: number;
  reportingCompanyId?: number;
  yearsOfData: number | null;
  note: string;
  hasUsers: boolean;
  noOfUsers?: number;
  organizationNameEn?: string;
  organizationNameFr?: string;
  companyNameEn?: string;
  companyNameFr?: string;
}

export const DriverReportOptionFactory = (
  id: number,
  note: string,
  organizationId: number,
  hasUsers: boolean,
  yearsOfData: number | null,
  noOfUsers?: number,
  reportingCompanyId?: number,
  organizationNameEn?: string,
  organizationNameFr?: string,
  companyNameEn?: string,
  companyNameFr?: string
): DriverReportOption => {
  return {
    id,
    yearsOfData,
    note,
    organizationId,
    reportingCompanyId,
    hasUsers,
    noOfUsers,
    organizationNameEn,
    organizationNameFr,
    companyNameEn,
    companyNameFr,
  };
};

export default DriverReportOption;
